<template>
  <div>
    <a-card title="普通复核">
      <a-button slot="extra" type="primary" ghost @click="goBack"><a-icon type="left" />返回</a-button>

      <a-row gutter="12">
        <a-col :span="4" style="width: 256px; margin-bottom: 12px">
          <a-input-search v-model="filterValue" placeholder="请扫描产品编号" />
        </a-col>
      </a-row>

      <a-spin :spinning="loading">
        <div>
          <a-table rowKey="id" size="small" :columns="columns" :data-source="filteredMaterialItems" :pagination="false">
            <div slot="total_quantity" slot-scope="value, item">
              <a-input-number v-model="item.total_quantity" size="small" @change="changeQuantity(item)" />
            </div>
            <div slot="charge_value" slot-scope="value, item">
              <a-input-number v-model="item.charge_value" size="small" />
            </div>
            <div slot="action" slot-scope="value, item, index">
              <a-button-group size="small">
                <a-button type="danger" @click="removeMaterial(index)">删除</a-button>
              </a-button-group>
            </div>
          </a-table>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="loading">创建</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { deliveryMaterialOption } from "@/api/option";
import { deliveryRecordCreate } from "@/api/stockOut";
import NP from "number-precision";

export default {
  data() {
    return {
      description: "详情",
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "名称",
          dataIndex: "material_name",
          key: "material_name",
        },
        {
          title: "产品编号",
          dataIndex: "material_number",
          key: "material_number",
        },
        {
          title: "剩余复核数量",
          dataIndex: "remain_quantity",
          key: "remain_quantity",
        },
        {
          title: "数量",
          key: "total_quantity",
          scopedSlots: { customRender: "total_quantity" },
        },
        {
          title: "单位",
          dataIndex: "unit",
          key: "unit",
        },
        {
          title: "计费量",
          dataIndex: "charge_value",
          scopedSlots: { customRender: "charge_value" },
        },
        {
          title: "计费单位",
          dataIndex: "charge_unit_display",
        },
        {
          title: "批次",
          dataIndex: "batch_number",
          key: "batch_number",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      loading: false,
      delivery_order_id: "",
      deliveryMaterialItems: [],
      filterValue: "",
    };
  },
  computed: {
    ...mapState({
      curWarehouse: (state) => state.user.currentWarehouse,
    }),
    filteredMaterialItems() {
      let filteredMaterialItems = [];
      for (let item of this.deliveryMaterialItems) {
        if (item.material_number.includes(this.filterValue)) {
          filteredMaterialItems.push(item);
        }
      }

      if (this.filterValue && this.filterValue != "" && filteredMaterialItems.length == 0) {
        this.$message.warn(`产品[${this.filterValue}]不存在`);
      }

      return filteredMaterialItems;
    },
  },
  methods: {
    moment,
    initData() {
      this.delivery_order_id = this.$route.query.id;
      this.getMaterial();
    },
    getMaterial() {
      deliveryMaterialOption({
        page_size: 999999,
        delivery_order: this.$route.query.id,
        is_completed: false,
      })
        .then((data) => {
          let materialItems = data.results.map((item) => {
            return {
              ...item,
              id: item.id,
              delivery_material: item.id,
              material_name: item.material_name,
              material_number: item.material_number,
              remain_quantity: item.remain_quantity,
              charge_value: this.getChargeValue(item, item.remain_quantity),
              total_quantity: item.remain_quantity,
              charge_unit: item.charge_unit,
              unit: item.unit,
              batch_number: item.batch_number,
            };
          });

          this.deliveryMaterialItems = [...materialItems];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeMaterial(index) {
      this.deliveryMaterialItems.splice(index, 1);
    },
    create() {
      if (this.deliveryMaterialItems.length == 0) {
        this.$message.warn("未添加产品");
        return;
      }

      let formData = {
        ...this.form,
        delivery_order: this.delivery_order_id,
        delivery_record_material_items: this.deliveryMaterialItems,
      };

      this.loading = true;
      deliveryRecordCreate(formData)
        .then((data) => {
          this.$message.success("创建成功");
          this.goBack();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    addSerialNumber(item) {
      item.total_quantity += 1;
    },
    getChargeValue(item, quantity) {
      if (item.charge_unit === "quantity") {
        return quantity || 0;
      } else if (item.charge_unit === "ton") {
        return NP.times(quantity, item.net_weight || 0, 0.001);
      } else if (item.charge_unit === "cube") {
        return NP.times(quantity, item.volume || 0);
      }
      return 0;
    },
    changeQuantity(item) {
      item.charge_value = this.getChargeValue(item, item.total_quantity);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
